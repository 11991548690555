<template>
  <v-container v-if="! isFieldLoading">
    <v-card tile subheader class="mt-6" elevation="0">
      <v-card-title class="pb-0 pt-0">
        A quel tournoi assistez-vous?
      </v-card-title>
      <v-card-text>
        <v-radio-group v-model="selectedCategory">
          <v-radio
            v-for="(category, k) in categories"
            :key="k"
            @change="changeCategory(k)"
            :label="category.nom_fr"
            :value="k"
          ></v-radio>
        </v-radio-group>
      </v-card-text>
    </v-card>
    <v-card tile subheader class="mt-6" elevation="0">
      <v-card-title class="pb-0 pt-0">
        Choisissez vos équipes préférées
      </v-card-title>
      <v-card-text>
        <v-select
          v-model="selectedTeam1"
          :items="teams"
          hide-details
          label="Equipe 1"
          single-line
          item-text="name"
          item-value="id"
          @change="setMyTeam"
          loading="isFieldLoading"
        ></v-select>
        <v-select
          v-model="selectedTeam2"
          :items="teams"
          hide-details
          label="Equipe 2"
          single-line
          item-text="name"
          item-value="id"
          @change="setMyTeam"
          loading="isFieldLoading"
        ></v-select>

        <v-btn
          small
          class="ref-button yellow darken-2"
          to="/referees/login"
        >
          Accès arbitres
        </v-btn>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script lang="js">
// import axios from 'axios'
// import { nextTick } from 'process'

// export default {
//   name: 'Home',
//   data: () => ({
//     categories: [],
//     selectedCategory: 0,
//     teams: [],
//     selectedTeam1: null,
//     selectedTeam2: null,
//     // show: false,
//     isFieldLoading: false,
//   }),
//   async mounted() {
//     this.$emit('set-menu', {
//       title: 'Paramètres',
//       link: '/',
//       icon: 'mdi-close',
//     })

//     this.selectedTeam1 = this.$store.getters.getTeam1
//     this.selectedTeam2 = this.$store.getters.getTeam2

//     axios.defaults.baseURL = 'https://sekulictest.e-tournament.ch/api/'
//     const response = await axios.get('json.php?get=categories')
//     this.categories = response.data
//     this.selectedCategory = this.$store.getters.getCategoryId
//     await this.refreshTeams();
//   },

//   methods: {
//     async changeCategory(k) {
//       this.isFieldLoading = true;
//       this.refreshTeams()
//         .then(() => {
//           this.$store.dispatch('changeCategoryId', k);
//           const response = axios.get('json.php?get=categories')
//           .then((response) => {
//             this.$store.dispatch('changeCategoryId', response.data[k].id_categorie);
//             this.isFieldLoading = false;
//           })
//         })
//         .catch((err) => {
//           console.error('MiNT Error : ', err);
//         });
//     },
//     async setMyTeam() {
//       this.$store.dispatch('changeTeam1', this.selectedTeam1)
//       this.$store.dispatch('changeTeam2', this.selectedTeam2)
//     },
//     async refreshTeams() {
//       const response2 = axios.get('json.php?get=teams&idc=' + this.$store.getters.getCategoryId)
//       .then((response2) => {
//         response2.data.forEach((team, k) => {
//         this.teams.push({
//           id: team.id_equipe,
//           name: team.club + ' ' + team.no,
//         })
//       })
//           })
//       .catch((err) => {
//         console.error('MiNT Error : ', err);
//       });
//     }
//   },
// }


import axios from 'axios'

export default {
  name: 'Home',
  data: () => ({
    categories: [],
    selectedCategory: 0,
    teams: [],
    selectedTeam1: null,
    selectedTeam2: null,
    isFieldLoading: false,
  }),
  async mounted() {

    this.$emit('set-menu', {
      title: 'Paramètres',
      link: '/',
      icon: 'mdi-close',
    })

    this.selectedTeam1 = this.$store.getters.getTeam1
    this.selectedTeam2 = this.$store.getters.getTeam2



    axios.defaults.baseURL = 'https://sekulictest.e-tournament.ch/api/'
    const response = await axios.get('json.php?get=categories')
    this.categories = response.data
    this.selectedCategory = this.$store.getters.getCategoryId
    console.log(this.selectedCategory)
    await this.refreshTeams();
  },

  methods: {
    async changeCategory(k) {
      this.isFieldLoading = true;
      this.teams = [];
      const response2 = await axios.get('json.php?get=teams&idc=' + this.categories[k].id_categorie)
      this.teams = response2.data.map(team => ({
        id: team.id_equipe,
        name: team.club + ' ' + team.no,
      }));



      this.$store.dispatch('changeCategoryId', k);
      this.$store.dispatch('changeCategory', this.categories[k].id_categorie);
      this.isFieldLoading = false;
    },
    async setMyTeam() {
      this.$store.dispatch('changeTeam1', this.selectedTeam1)
      this.$store.dispatch('changeTeam2', this.selectedTeam2)
    },
    async refreshTeams() {
      const response2 = axios.get('json.php?get=teams&idc=' + this.$store.getters.getCategoryId)
      .then((response2) => {
        response2.data.forEach((team, k) => {
        this.teams.push({
          id: team.id_equipe,
          name: team.club + ' ' + team.no,
        })
      })
          })
      .catch((err) => {
        console.error('MiNT Error : ', err);
      });
    }
  },
}
</script>

<style scoped lang="scss">
  .ref-button {
    position: absolute;
    right: 10px;
    margin-top: 2rem;
  }
</style>
<template>
  <v-container class="mb-12" v-if="group">
    <v-card-title subheader elevation="0">
      <v-card-title class="px-1 pb-0 pt-6 headline">
        Groupe {{ group.info.group }}
      </v-card-title>
      <v-card-text class="px-2 pt-2">
        <v-row v-for="(team, i) in group.teams" :key="i" class="ma-0">
          <span :class="setColor(team.id)">{{ team.name }}</span>
        </v-row>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card-title>

    <v-expansion-panels v-model="panel" flat accordion>
      <v-expansion-panel>
        <v-expansion-panel-header class="px-2 headline"
          >Horaires des matchs
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card-text class="mx-0 pt-0 px-0">
            <v-simple-table>
              <thead>
                <tr>
                  <th class="px-1">T</th>
                  <th class="px-1">Equipe 1</th>
                  <th class="px-1">Equipe 2</th>
                  <th class="px-1">Heure</th>
                  <th class="px-1"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(match, k) in group.schedules"
                  :key="k"
                  :class="setColorMatch(match.team1_id, match.team2_id)"
                >
                  <td class="px-1">{{ match.field }}</td>
                  <td class="px-1">
                    <span>{{ match.team1 }}</span>
                  </td>
                  <td class="px-1">
                    <span>{{ match.team2 }}</span>
                  </td>
                  <td class="px-1">{{ match.time.substring(0, 5) }}</td>
                  <td class="px-1" v-if="$store.getters.getIsReferee">
                    <v-btn
                      icon
                      color="indigo"
                      :to="'/score/' + match.id_match"
                    >
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody></v-simple-table
            >
          </v-card-text>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="px-2 headline"
          >Résultats</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-card-text class="mx-0 px-0" v-if="score">
            <v-simple-table>
              <thead>
                <tr>
                  <th class="px-1">Equipe 1</th>
                  <th class="px-1">Equipe 2</th>
                  <th class="px-1 text-right">Score</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(match, k) in score.schedule"
                  :key="k"
                  :class="setColorMatch(match.team1_id, match.team2_id)"
                >
                  <td class="px-1">
                    <span>{{ match.team1 }}</span>
                  </td>

                  <td class="px-1">
                    <span>{{ match.team2 }}</span>
                  </td>
                  <td
                    class="px-1 text-right"
                    v-html="
                      match.score1 ? match.score1 + '-' + match.score2 : ''
                    "
                  ></td>
                </tr></tbody
            ></v-simple-table>
          </v-card-text>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header class="px-2 headline"
          >Classement</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-card-text class="mx-0 px-0" v-if="score">
            <v-simple-table>
              <thead>
                <tr>
                  <th class="px-1">Rang</th>
                  <th class="px-1">Equipe</th>
                  <th class="px-1">J</th>
                  <th class="px-1">G</th>
                  <th class="px-1">N</th>
                  <th class="px-1">P</th>
                  <th class="px-1 text-right">Pts</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(pos, k) in score.ranking" :key="k">
                  <td class="px-1" v-html="k + 1"></td>
                  <td class="px-1">
                    <span>{{ pos.team }}</span>
                  </td>
                  <td class="px-1">{{ pos.played }}</td>
                  <td class="px-1">{{ pos.won }}</td>
                  <td class="px-1">{{ pos.drawn }}</td>
                  <td class="px-1">{{ pos.lost }}</td>
                  <td class="px-1 text-right">
                    <strong>{{ pos.points }}</strong>
                  </td>
                </tr>
              </tbody></v-simple-table
            >
          </v-card-text>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  name: 'TimetablePerGroup',
  props: ['groupId'],

  data: () => ({
    categories: [],
    selectedCategory: 0,
    group: null,
    score: null,
    panel: 0,
  }),
  methods: {
    setColor: function (team) {
      if (team == this.team1 || team == this.team2) {
        return 'yellow lighten-3'
      }
    },
    setColorMatch: function (team1, team2) {
      if (team1 == this.team1 || team1 == this.team2) {
        return 'yellow lighten-3'
      }
      if (team2 == this.team1 || team2 == this.team2) {
        return 'yellow lighten-3'
      }
    },
  },
  async mounted() {
    this.team1 = this.$store.getters.getTeam1
    this.team2 = this.$store.getters.getTeam2

    axios.defaults.baseURL = 'https://sekulictest.e-tournament.ch/api/'
    const response = await axios.get(
      'json.php?get=groups-schedule-bygroup&idg=' + this.groupId
    )
    console.log(response.data)
    this.group = response.data

    this.$emit('set-menu', {
      title: 'Groupe ' + this.group.info.group,
      back: '/groups',
      icon: 'mdi-close',
    })

    // FIXME: this.score is null
    const response2 = await axios.get(
      '/json.php?get=ranking-bycategory&idc=' +
        this.$store.getters.getCategoryId
    )

    for (const score of response2.data.groups) {
      if (score.info.id === this.groupId) {
        this.score = score
      }
    }

    console.log(this.score);
  },
}
</script>

<style lang="scss">
.v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>

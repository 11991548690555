import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Settings from '../views/Settings.vue'
import Groups from '../views/Groups.vue'
import Teams from '../views/Teams.vue'
import Finales from '../views/Finales.vue'
import TimetablePerTeam from '../views/TimetablePerTeam.vue'
import TimetablePerGroup from '../views/TimetablePerGroup.vue'
import Score from '../views/Score.vue'

import store from '@/store'

Vue.use(VueRouter)

const checkCategory = (to, from, next) => {
  if (store.getters.getCategoryId) {
    next()
  } else {
    next('/settings')
  }
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    beforeEnter: checkCategory,
  },
  {
    path: '/groups',
    name: 'Groups',
    component: Groups,
    beforeEnter: checkCategory,
  },
  {
    path: '/score/:id',
    name: 'Score',
    component: Score,
    props: true,
    beforeEnter: checkCategory,
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
  },
  {
    path: '/teams',
    name: 'Teams',
    component: Teams,
    beforeEnter: checkCategory,
  },
  {
    path: '/finales',
    name: 'Finales',
    component: Finales,
    beforeEnter: checkCategory,
  },
  {
    path: '/teams/timetableperteam/:groupId/:teamId',
    name: 'TimetablePerTeam',
    component: TimetablePerTeam,
    props: true,
    beforeEnter: checkCategory,
  },
  {
    path: '/groups/timetablepergroup/:groupId',
    name: 'TimetablePerGroup',
    component: TimetablePerGroup,
    props: true,
    beforeEnter: checkCategory,
  },
  {
    path: '/referees/login',
    name: 'RefereesLogin',
    component: () => import('@/views/RefLogin.vue'),
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes,
})

export default router
